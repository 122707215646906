import React, {useEffect, useState} from 'react'
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import CTABox from "../components/CTABox";
import PrimaryMenu from "../components/PrimaryMenu";
import SubPageNav from "../components/SubPageNav";
import IconList from "../components/IconList";
import ContentSeries from '../components/ContentSeries';

const ContentSectionLanding = ({pageContext: pagePassedContext}) => {
    
    console.log(pagePassedContext)
    const [pageContext, setContext] = useState(pagePassedContext);
    
    // get the data vars we need from the json data
    // this came from http://api.vanarts.com/api/pages
    //let data = pageContext.content.json_data.data;
    let navigation = pageContext.flyoutnav;
    let data = pageContext.content.json_data.data;
    let header = data.header;
    let nav = pageContext.flyoutnav;
    let linkList = data.content.subLinks;
    let actions = data.actions;
    let subcontent = data.subcontent;
    let contentBlocks = data.contentBlocks;

    // THIS GETS THE SUB NAVIGATION
    const subnavName = data.navigation;

    /**** MOVE TO A FUNCTION FILE  */
    function search(nameKey, myArray){
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return myArray[i];
            }
        }
    }

    let subnav = search(subnavName, navigation).json_data.data.content.subLinks
    
    useEffect(function(){
        fetch("http://api.vanarts.com:3000/pages/"+pageContext.content.id).then(response=>{
            return response.json();
        }).then(function(json){
            setContext(json)
        })
    }, [])
    
    return (
        <Layout nav={nav}>
            <Hero image={header.cover}>
                <PrimaryMenu nav={nav}/>
            </Hero>
            
            <SubPageNav title={data.header.title} linksData={subnav} type="subpage">
            </SubPageNav>

            <div className="wide-pad-magic top-content-section">
                <article className="standard-content">
                    <div className="pad">
                        <div dangerouslySetInnerHTML={{__html: data.content.bodyintro}}/>
                    </div>
                    {
                        linkList[0].name && <IconList links={linkList}/>
                    }
                </article>
            </div>


            <div style={{"clear":"both"}}>       
            {
                contentBlocks.sectionTitle && 
                
                <ContentSeries 
                    title={contentBlocks.sectionTitle} 
                    content={contentBlocks} 
                    theme={contentBlocks.theme}
                />
            }
            </div>


            {
                subcontent[0].content &&
                <div className="wide-pad-magic">
                    <article className="standard-content">
                        {
                            subcontent.map((content, i)=>(
                                <div className={content.theme}>
                                    {content.header && <h2 className="contentBlockHeader">{content.header}</h2>}
                                    <div dangerouslySetInnerHTML={{__html: content.content}} className="themeContent pad"/>
                                </div>
                            ))
                        }
                    </article>
                </div>
            }

            <div style={{"clear":"both"}}>
            {
                actions.boxes.map((action, i)=>(
                    <CTABox label={action.ctaText} url={action.url} image={action.image}>
                         <div dangerouslySetInnerHTML={{__html: action.content}} />
                    </CTABox> 
                ))
            }
            </div>
        
            

        </Layout>
    )
}

export default ContentSectionLanding;