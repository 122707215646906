import React from 'react'
import { Link } from "gatsby"
import './IconList.css'

const IconList = ({links, className, id}) => {

    return (
        <div id="programListGroup" className={className}>
            {
                links.map((link, i) => {
                    if (link.icon !="" && link.icon!="Make a selection")
                    {
                        return (
                            <div className="listItem" key={i}>
                                <img src={"http://api.vanarts.com:3000/assets/"+link.icon} alt={link.name}/>
                                <div className="detail">
                                    <h2><Link to={link.slug}>{link.name}</Link></h2>
                                    <Link to={link.slug}>See More +</Link>
                                </div>
                            </div>
                        )
                    } else {
                        /* for list items with no icons */
                        let actionText = (link.spec) ? (link.spec) : "Learn More About This Course +";
                        return (
                            <div className="listItem" key={i}>
                                <div className="detail">
                                    <h2><Link to={link.slug}>{link.name}</Link></h2>
                                    <Link to={link.slug}>{actionText}</Link>
                                </div>
                            </div>
                        )
                    }
               
                })
            }
        </div>
    )
}

export default IconList;