import React from "react";
import "./ContentSeries.css";

export default function ContentSeries({content, title, theme, cols}){
    
    theme = theme || "";
    let colstheme = (cols) ? "cols":"";

    return (
        <section className={`content-series ${theme} ${colstheme}`}>
            <div>
            <h2>{title}</h2>
            <div>
            {
                content.blocks.map( (entry, i) => (
                    <article key={i} className="row">
                        <div className="image wide-7_12">
                            {entry.image && <div className="imageContainer"><img src={`http://api.vanarts.com:3000/assets/${entry.image}`} alt={entry.title} /></div>}
                        </div>
                        <div className="details handle wide-5_12">
                            <header>
                                <h3>
                                    <span>0{i+1}</span>
                                    <span>{entry.title}</span>
                                </h3>  
                            </header>

                            <div dangerouslySetInnerHTML={{ __html: entry.content }}>
                                
                            </div>
                        </div>

                    </article>
                ))
            }
            </div>
            </div>
        </section>
    )
}