import React, {useEffect, useState} from 'react'
import {Link} from 'gatsby'

import "./SubPageNav.css"

import arrow from './images/triangle.svg';

export default function({title ,children, linksData, linkLabel, type}){
    const [show, setShow] = useState(0)
    linkLabel = linkLabel || "Other Course Types";
    console.log("subpage nav", linksData)
    
    const showMenu = function(e){
        document.querySelector(".subnav ul").style.display = (document.querySelector(".subnav ul").style.display=="block") ? "none" : "block";
        rotateArrow();
    }

    const rotateArrow = function(e){
        document.querySelector(".rotate").classList.toggle("flip"); 
    }
        
    if (type=="subpage"){ 
        return (
            <div className="navbox nopad subpageheader">
                <h1>{title}</h1>
                <nav className="subnav">
                    <div  className="rotate"  onClick={e=>showMenu(e)}>
                        <h2>{linkLabel}</h2>
                        <span className="arrow"><img src={arrow} alt="Down Arrow"/></span>
                    </div>
                    <ul>
                        {
                            linksData.map(link=>{
                                return <li><Link to={link.slug}>{link.name}</Link></li>
                            })
                        }                    
                    </ul>
                </nav>
            </div>
        )
    } else {
        return (
            <div className="navbox nopad">
                <div className="content">
                    <h1>{title}</h1>
                    {children}
                </div>
                <nav className="subnav">
                    <div className="rotate" onClick={e=>showMenu()}>
                        <h2>{linkLabel}</h2>
                        <span className="arrow"><img src={arrow} alt="Down Arrow"/></span>
                    </div>
                    <ul>
                        {
                            linksData.map((link,i)=>{
                                return <li key={i}><Link to={link.slug}>{link.name}</Link></li>
                            })
                        }                    
                    </ul>
                </nav>
            </div>
        )
    }
}